export const environmentObj =
{
  "domainKey": "https://temp.api.bambumeta.software",
  "rootPath": "admin",
  "additionalApiDomains": [],
  "cognito": {
    "aws_user_pools_id": "us-east-1_FNGXhABWJ",
    "aws_user_pools_web_client_id": "231ii036sqvpbmjds0ku4b6r7i",
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:2e806093-2678-4d09-8e1e-f1888aad1db8",
    "oauth": {
      "domain": "bwa-04e7eb87-a3bf-5376-8855-54456a8555fd-bambu.auth.us-east-1.amazoncognito.com",
      "scope": [
        "aws.cognito.signin.user.admin",
        "email",
        "https://admin.bambumeta.software/tenant-admin",
        "openid",
        "profile"
      ],
      "redirectSignIn": "https://d11sl3t9vhb5ab.cloudfront.net/",
      "redirectSignOut": "https://d11sl3t9vhb5ab.cloudfront.net/",
      "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
      "email"
    ],
    "aws_cognito_signup_attributes": [
      "email"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
      "SMS",
      "EMAIL"
    ],
    "aws_cognito_password_protection_settings": {
      "passwordPolicyCharacters": [],
      "passwordPolicyMinLength": 6
    },
    "aws_cognito_verification_mechanisms": [
      "email"
    ]
  }
}